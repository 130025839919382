import { useLocation, useNavigate } from 'react-router';

import Nav from './Nav/Nav';
import NavItem from './NavItem/NavItem';
import { withRouter } from '../../Utils/router-util';
import { PathEntry } from '../../routes';
import NavbarBrand from './NavbarBrand/NavbarBrand';

const Sidebar = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const getPathEntryAsObjectKeys = () => {
		return Object.keys(PathEntry);
	};

	return (
		<Nav>
			<div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap md:justify-start justify-between items-center w-full mx-auto">
				<button
					className="cursor-pointer text-gray-200 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
					type="button"
				>
					<i className="fas fa-bars"></i>
				</button>
				<NavbarBrand />
				<div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
					<hr className="my-4 md:min-w-full" />
					<ul className="md:flex-col md:min-w-full flex flex-col list-none">
						{getPathEntryAsObjectKeys().map((key, idx) => {
							return (
								<NavItem
									text={PathEntry[key].pageTitle}
									faIconClass={PathEntry[key].navFAIconClass}
									isActive={location.pathname === PathEntry[key].path}
									onClick={() => navigate(PathEntry[key].path)}
									key={idx}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		</Nav>
	);
};

export default withRouter(Sidebar);
