import { useLocation, useNavigate } from 'react-router-dom';

import { withRouter } from '../../Utils/router-util';
import { getPathEntryFromPath } from '../../routes';

const Content = ({ children }) => {
	const location = useLocation();

	const getPageTitle = () => {
		const pathEntry = getPathEntryFromPath(location.pathname);
		return pathEntry.pageTitle;
	};

	const getPageSubtitle = () => {
		const pathEntry = getPathEntryFromPath(location.pathname);
		return pathEntry?.pageSubtitle;
	};

	return (
		<section className="md:ml-64 bg-gray-100 text-gray-700 font-normal text-base">
			<div className='border-solid border-b-2 border-gray-400 pb-3 mb-3 text-2xl'>
				<p className={`font-semibold ${getPageSubtitle() ? 'mb-1' : ''} `}>
					{getPageTitle()}
				</p>
				{getPageSubtitle() && (
					<p className="mb-0 text-base">{getPageSubtitle()}</p>
				)}
			</div>
			<div className="md:mt-8">{children}</div>
		</section>
	);
};

export default withRouter(Content);
