import PropTypes from 'prop-types';
import { CodeBlock, dracula } from "react-code-blocks";
import { Store } from 'react-notifications-component';

import Header from '../Header/Header';
import Button from '../Button/Button';
import { ButtonType } from '../Button/constants';
import { copyToClipboard } from '../../../Utils/Utils';

const NoteBlock = ({
	codeBlock,
	codeLanguage,
	headerText,
	remarks,
	source
}) => {
	return (
		<div className="w-full gap-3 flex flex-col">
			<Header text={headerText} />
			<CodeBlock
				text={codeBlock}
				language={codeLanguage}
				showLineNumbers={false}
				theme={dracula}
			/>
			<div className='flex justify-between w-full items-center'>
				<Button
					iconClass="far fa-copy"
					text="Copy Snippet"
					type={ButtonType.SUCCESS}
					onClick={() => {
						copyToClipboard(codeBlock);
						Store.addNotification({
							title: "SUCCESS!",
							message: "Snippet copied successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: true,
								showIcon: true
							}
						});
					}}
				/>
				{
					source && <div>
						Source: <a href={source} target="_blank">Open</a>
					</div>
				}
			</div>
			{
				remarks && <div className='border rounded border-gray-500 bg-gray-300 p-4 shadow'>
					{remarks}
				</div>
			}
		</div>
	)
}

NoteBlock.propTypes = {
	codeBlock: PropTypes.string.isRequired,
	codeLanguage: PropTypes.string.isRequired,
	headerText: PropTypes.string.isRequired,
	remarks: PropTypes.element,
	source: PropTypes.string
}

export default NoteBlock;
