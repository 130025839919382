import NoteBlock from "../../Components/UI/NoteBlock/NoteBlock";
import data from "../../Data/data";

const Javascript = () => {
	return (
		<div className="w-full gap-6 flex flex-col">
			{
				data.javascript.map((noteBlock, idx) =>
					<div
						className={`${idx !== data.javascript.length - 1 && "border-b border-gray-300 pb-6"}`}
						key={idx}
					>
						<NoteBlock
							headerText={noteBlock.headerText}
							codeBlock={noteBlock.codeBlock}
							codeLanguage={noteBlock.codeLanguage}
							remarks={noteBlock.remarks}
							source={noteBlock.source}
						/>
					</div>)
			}
		</div>
	);
};

export default Javascript;
