import Card from "../../Components/UI/Card/Card";
import data from "./__data__/data";

const General = () => {
	return (
		<div className="w-full gap-6 flex flex-col">
			{
				data.map((noteBlock, idx) =>
					<Card
						bordered
						cardTitle={noteBlock.headerText}
						cardBody={noteBlock.content}
						cardFooter={noteBlock.source && <span>Source: <a href={noteBlock.source} target="_blank" rel="noopener noreferrer">Open</a></span>}
						key={idx}
					/>
				)
			}
		</div>
	)
}

export default General;
