export function clone(o) {
	return JSON.parse(JSON.stringify(o));
}

export const isNotNullObject = (input) => {
	if (typeof input === 'object' && !Array.isArray(input) && input !== null) {
		return true;
	}
	return false;
};

export const copyToClipboard = str => {
	navigator.clipboard.writeText(str);
};
