import PropTypes from 'prop-types';

const Header = ({
	text
}) => {
	return (
		<div className='bg-sky-700 text-gray-100 font-semibold py-3 px-2 w-full text-lg border-l-8 border-sky-900'>
			{text}
		</div>
	)
}

Header.propTypes = {
	text: PropTypes.string.isRequired
}

export default Header;
