import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useEffect, useState } from 'react';

import Sidebar from './Sidebar/Sidebar';
import Content from './Content/Content';
import PageWrapper from './PageWrapper/PageWrapper';
import Button from '../Components/UI/Button/Button';
import { ButtonType } from '../Components/UI/Button/constants';

const Layout = ({ children }) => {

	const [showScrollTop, setShowScrollTop] = useState(false);

	const handleScroll = () => {
		if (document.getElementById("page-wrapper").scrollTop > 350) {
			setShowScrollTop(true);
		} else {
			setShowScrollTop(false);
		}
	};


	useEffect(() => {
		document.getElementById("page-wrapper").addEventListener('scroll', handleScroll, true);
	}, []);

	return (
		<PageWrapper>
			<ReactNotifications />
			<Sidebar />
			<Content>{children}</Content>
			{
				showScrollTop &&
				<Button
					iconClass="fas fa-chevron-up"
					type={ButtonType.INFO}
					classes={['fixed', 'bottom-4', 'right-6']}
					fullRounded
					onClick={() => {
						document.getElementById("page-wrapper").scrollTo({
							top: 0,
							behavior: 'smooth',
						});
					}}
				/>
			}
		</PageWrapper>
	);
};

export default Layout;
