import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import './Button.css';
import { ButtonType, ButtonSize } from './constants';

const Button = ({
	block = false,
	classes = [],
	disabled = false,
	fullRounded = false,
	iconClass,
	onClick,
	outline = false,
	size = ButtonSize.LG,
	text,
	type,
	...rest
}) => {
	const getButtonClass = () => {
		switch (type) {
			case ButtonType.PRIMARY:
				return 'btn-primary';
			case ButtonType.SECONDARY:
				return 'btn-secondary';
			case ButtonType.SUCCESS:
				return 'btn-success';
			case ButtonType.DANGER:
				return 'btn-danger';
			case ButtonType.WARNING:
				return 'btn-warning';
			case ButtonType.INFO:
				return 'btn-info';
			case ButtonType.CYAN:
				return 'btn-cyan';
			case ButtonType.WHITE:
				return 'btn-white';
			case ButtonType.LIGHT_GRAY:
				return 'btn-light-gray';
			case ButtonType.DARK_GRAY:
				return 'btn-dark-gray';
			case ButtonType.DARK:
				return 'btn-dark';
			default:
				return 'btn-light';
		}
	};

	return (
		<button
			type="button"
			className={`
        ${getButtonClass()}${outline ? '-outline' : ''}
        inline-block
        ${size}
        text-white
        uppercase
        font-bold
        text-xs
        leading-tight
        ${fullRounded ? 'rounded-full' : 'rounded'}
        shadow-md
        hover:shadow-lg
        focus:shadow-lg
        focus:outline-none
        focus:ring-0
        active:shadow-lg
        transition
        duration-150
        ease-in-out
        ${block ? ' w-full' : ''}${disabled ? ' opacity-60 cursor-not-allowed' : ''}
        ${!isEmpty(classes) ? classes.join(' ') : ''}
        `}
			onClick={onClick ? () => onClick() : undefined}
			disabled={disabled}
			{...rest}
		>
			{iconClass !== undefined && <i className={`${iconClass} ${text ? 'mr-2' : ''}`} />}
			{text && text}
		</button>
	);
};

Button.propTypes = {
	block: PropTypes.bool,
	classes: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool,
	fullRounded: PropTypes.bool,
	iconClass: PropTypes.string,
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	size: PropTypes.string,
	text: PropTypes.string,
	type: PropTypes.string,
}

export default Button;
