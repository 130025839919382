import PropTypes from 'prop-types';

const Nav = ({ children }) => (
	<nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-sky-900 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-2">
		{children}
	</nav>
);

Nav.propTypes = {
	children: PropTypes.element.isRequired,
};

export default Nav;
