import './NavbarBrand.css';
import constants from '../../../Constants';

const NavbarBrand = () => {
	return (
		<div className="md:flex md:flex-col text-center md:pb-0 text-gray-200 mr-0 inline-block whitespace-nowrap text-xl uppercase font-bold p-4 px-0 justify-center items-center">
			<p className='tracking-wider'>{constants.APP_NAME}</p>
			<span className="hidden md:block text-center">
				<i className="fas fa-laptop-code my-4" style={{ fontSize: '310%' }} />
			</span>
		</div>
	);
};

export default NavbarBrand;
