import { Route, Routes } from 'react-router-dom';
import './App.css';

import { PathEntry } from './routes';
import Home from './Views/Home/Home';
import NotFound from './Views/NotFound/NotFound';
import Layout from './Layout/Layout';
import Php from './Views/Php/Php';
import Wordpress from './Views/Wordpress/Wordpress';
import Javascript from './Views/Javascript/Javascript';
import React from './Views/React/React';
import Linux from './Views/Linux/Linux';
import General from './Views/General/General';

function App() {
	return (<Routes>
		<Route
			path={PathEntry.home.path}
			element={
				<Layout>
					<Home />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.php.path}
			element={
				<Layout>
					<Php />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.wordpress.path}
			element={
				<Layout>
					<Wordpress />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.javascript.path}
			element={
				<Layout>
					<Javascript />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.react.path}
			element={
				<Layout>
					<React />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.linux.path}
			element={
				<Layout>
					<Linux />
				</Layout>
			}
		/>
		<Route
			path={PathEntry.general.path}
			element={
				<Layout>
					<General />
				</Layout>
			}
		/>
		<Route path="*" element={<NotFound />} />
	</Routes>);
}

export default App;
