import PropTypes from 'prop-types';

const Card = ({
	cardBody,
	cardFooter,
	cardTitle
}) => {
	return (
		<div className="flex justify-center">
			<div className="rounded-lg shadow bg-white w-full border border-gray-300">
				{cardTitle && (
					<div
						className="px-5 py-4 flex flex-row w-full justify-between items-center bg-gray-100 border-b border-gray-300"
					>
						<h5 className="text-xl leading-tight font-medium">{cardTitle}</h5>
					</div>
				)}
				<div className="p-5">{cardBody}</div>
				{
					cardFooter && <div className="px-5 py-4 bg-gray-100 border-t border-gray-300">{cardFooter}</div>
				}
			</div>
		</div>
	);
};

Card.propTypes = {
	cardBody: PropTypes.element,
	cardFooter: PropTypes.element,
	cardTitle: PropTypes.string
}

export default Card;
