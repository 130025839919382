export const ButtonType = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	SUCCESS: 'success',
	DANGER: 'danger',
	WARNING: 'warning',
	INFO: 'info',
	CYAN: 'cyan',
	WHITE: 'white',
	LIGHT_GRAY: 'light_gray',
	DARK_GRAY: 'dark_gray',
	DARK: 'dark',
};

export const ButtonSize = {
	SM: 'py-2.5 px-3',
	MD: 'py-2.5 px-4',
	LG: 'py-2.5 px-5',
	XL: 'py-2.5 px-6',
};
