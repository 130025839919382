import { useNavigate } from 'react-router-dom';
import Button from '../../Components/UI/Button/Button';
import { ButtonType } from '../../Components/UI/Button/constants';
import { PathEntry } from '../../routes';

function NotFound() {
	const navigate = useNavigate();

	return (
		<div className="h-screen w-full flex items-center justify-center flex-col bg-gray-100">
			<div className="max-w-screen-md flex items-center justify flex-col">
				<p className="text-6xl font-bold text-gray-600 mb-3">404</p>
				<i className="far fa-times-circle text-6xl text-red-700 mb-5"></i>
				<p className="text-base text-gray-500 text-center mb-5">
					Η σελίδα που ψάχνετε δεν βρέθηκε στον διακομιστή. Παρακαλώ επικαιροποιήστε τον σύνδεσμο
					και ξαναπροσπαθήστε
				</p>
				<Button
					classes={['text-base']}
					iconClass="fas fa-home"
					onClick={() => navigate(PathEntry.home.path)}
					style={{ padding: 20 }}
					text="ΕΠΙΣΤΡΟΦΗ ΣΤΗΝ ΑΡΧΙΚΗ"
					type={ButtonType.PRIMARY}
				/>
			</div>
		</div>
	);
}

export default NotFound;
