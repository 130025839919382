import { Store } from 'react-notifications-component';
import { CodeBlock, dracula } from "react-code-blocks";
import "./React.css";

import data from './__data__/data';
import { copyToClipboard } from '../../Utils/Utils';
import Button from '../../Components/UI/Button/Button';
import { ButtonType } from '../../Components/UI/Button/constants';
import Header from "../../Components/UI/Header/Header";

const React = () => {
	return (
		<div className="w-full gap-3 flex flex-col">
			<Header text="Useful npm packages" />
			<div className='overflow-x-auto'>
				<table className="w-full" style={{ minWidth: 750 }}>
					<thead className="bg-gray-300 border-b-2 border-t-2 border-gray-400 font-semibold">
						<tr>
							<th className="px-3 py-4 text-left tracking-wide">How to install</th>
							<th className="px-3 py-4 text-left tracking-wide">Description</th>
							<th className="px-3 py-4 text-center tracking-wide">Actions</th>
						</tr>
					</thead>
					<tbody>
						{
							data.utils.map((line, idx) => {
								return <tr key={idx} className="border-b border-gray-300 text-base">
									<td className="px-3 py-2">
										<CodeBlock
											text={line.codeBlock}
											language="bash"
											showLineNumbers={false}
											theme={dracula}
										/>
									</td>
									<td className="px-3 py-2">{line.description}</td>
									<td className="px-3 py-2 text-center" style={{ width: 287 }}>
										<Button
											classes={["mr-2", "mb-2", "lg:mb-0"]}
											iconClass="far fa-copy"
											onClick={() => {
												copyToClipboard(line.codeBlock);
												Store.addNotification({
													title: "SUCCESS!",
													message: "Snippet copied successfully",
													type: "success",
													insert: "top",
													container: "top-right",
													animationIn: ["animate__animated", "animate__fadeIn"],
													animationOut: ["animate__animated", "animate__fadeOut"],
													dismiss: {
														duration: 5000,
														onScreen: true,
														showIcon: true
													}
												});
											}}
											text="Copy Snippet"
											type={ButtonType.SUCCESS}
										/>
										<Button
											iconClass="fas fa-external-link-alt"
											onClick={() => window.open(line.source, "_blank", 'noopener')}
											text="Source"
											type={ButtonType.INFO}
										/>
									</td>
								</tr>
							})
						}
						<tr>
							<td colSpan={4} className="text-center font-semibold py-2 bg-gray-300 border-t-2 border-b-2 border-gray-400 tracking-wider">GUI purposes</td>
						</tr>
						{
							data.gui.map((line, idx) => {
								return <tr key={idx} className="border-b border-gray-300 text-base">
									<td className="px-3 py-2">
										<CodeBlock
											text={line.codeBlock}
											language="bash"
											showLineNumbers={false}
											theme={dracula}
										/>
									</td>
									<td className="px-3 py-2">{line.description}</td>
									<td className="px-3 py-2 text-center" style={{ width: 287 }}>
										<Button
											classes={["mr-2", "mb-2", "lg:mb-0"]}
											iconClass="far fa-copy"
											onClick={() => {
												copyToClipboard(line.codeBlock);
												Store.addNotification({
													title: "SUCCESS!",
													message: "Snippet copied successfully",
													type: "success",
													insert: "top",
													container: "top-right",
													animationIn: ["animate__animated", "animate__fadeIn"],
													animationOut: ["animate__animated", "animate__fadeOut"],
													dismiss: {
														duration: 5000,
														onScreen: true,
														showIcon: true
													}
												});
											}}
											text="Copy Snippet"
											type={ButtonType.SUCCESS}
										/>
										<Button
											iconClass="fas fa-external-link-alt"
											onClick={() => window.open(line.source, "_blank", 'noopener')}
											text="Source"
											type={ButtonType.INFO}
										/>
									</td>
								</tr>
							})
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default React;
