import './NavItem.css';

const NavItem = ({
	faIconClass,
	isActive,
	onClick,
	text,
}) => (
	<li
		className={`items-center cursor-pointer hover:bg-sky-600 duration-150 ease-in-out mb-2 py-3 px-4 text-gray-100 ${isActive ? 'bg-sky-600 ' : ''}`}
		onClick={() => onClick()}>
		<span className="text-sm uppercase font-bold block unselectable-text">
			<i className={`${faIconClass} mr-3 text-base opacity-80`} />
			{text}
		</span>
	</li>
);
export default NavItem;
