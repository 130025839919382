import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// Fontawesome icons
import './Assets/Vendors/Fontawesome/css/fa-brands.css';
import './Assets/Vendors/Fontawesome/css/fa-regular.css';
import './Assets/Vendors/Fontawesome/css/fa-solid.css';
import './Assets/Vendors/Fontawesome/css/fontawesome.css';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<ScrollToTop />
		<App />
	</BrowserRouter>,
);
