export const PathEntry = {
	home: {
		path: '/',
		pageTitle: 'Home Page',
		pageSubtitle: 'My code snippets repository',
		navFAIconClass: 'fas fa-home',
		includeInViewNavigation: false,
	},
	php: {
		path: '/php',
		pageTitle: 'PHP',
		pageSubtitle: 'Code snippets for PHP programming language',
		navFAIconClass: 'fab fa-php',
		includeInViewNavigation: false,
	},
	wordpress: {
		path: '/wordpress',
		pageTitle: 'Wordpress',
		pageSubtitle: 'Code snippets for Wordpress framework',
		navFAIconClass: 'fab fa-wordpress',
		includeInViewNavigation: false,
	},
	javascript: {
		path: '/javascript',
		pageTitle: 'Javascript',
		pageSubtitle: 'Code snippets for Javascript programming language',
		navFAIconClass: 'fab fa-js',
		includeInViewNavigation: false,
	},
	react: {
		path: '/react',
		pageTitle: 'React',
		pageSubtitle: 'Code snippets for React JS library',
		navFAIconClass: 'fab fa-react',
		includeInViewNavigation: false,
	},
	linux: {
		path: '/linux',
		pageTitle: 'Linux',
		pageSubtitle: 'Installation of softwares and bash scripts',
		navFAIconClass: 'fab fa-linux',
		includeInViewNavigation: false,
	},
	general: {
		path: '/general',
		pageTitle: 'General',
		pageSubtitle: 'Articles / Thoughts / Research / Configurations etc.',
		navFAIconClass: 'far fa-sticky-note',
		includeInViewNavigation: false,
	}
};

export const getPathEntryFromPath = (path) => {
	let output = PathEntry.home;
	Object.keys(PathEntry).forEach((key) => {
		if (PathEntry[key].path === path) {
			output = PathEntry[key];
		}
	});
	return output;
};
