// https://stackoverflow.com/a/65794238/274677
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export function withRouter(Child) {
	return (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		return <Child {...props} routing={{ navigate, location, params }} />;
	};
}
