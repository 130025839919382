import { CodeBlock, dracula } from "react-code-blocks";
import { Store } from 'react-notifications-component';

import Button from "../../../Components/UI/Button/Button";
import { ButtonType } from "../../../Components/UI/Button/constants";
import Mention from "../../../Components/UI/Mention/Mention";
import { copyToClipboard } from "../../../Utils/Utils";

const data = [
	{
		headerText: "How show git branch in Ubuntu terminal",
		steps: [
			{
				title: "Open .bashrc file",
				content: <div>
					<CodeBlock
						text={`sudo nano ~/.bashrc`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo nano ~/.bashrc");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Insert the following lines and save it",
				content: <div>
					<CodeBlock
						text={`function parse_git_branch () {
	git branch 2> /dev/null | sed -e '/^[^*]/d' -e 's/* \\(.*\\)/(\\1)/'
}
RED="\\[\\033[01;31m\\]"
YELLOW="\\[\\033[01;33m\\]"
GREEN="\\[\\033[01;32m\\]"
BLUE="\\[\\033[01;34m\\]"
NO_COLOR="\\[\\033[00m\\]"
# without host
PS1="$GREEN\\u$NO_COLOR:$BLUE\\w$YELLOW\\$(parse_git_branch)$NO_COLOR\\$ "
# with host
# PS1="$GREEN\\u@\\h$NO_COLOR:$BLUE\\w$YELLOW\\$(parse_git_branch)$NO_COLOR\\$ "`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`function parse_git_branch () {
								git branch 2> /dev/null | sed -e '/^[^*]/d' -e 's/* \\(.*\\)/(\\1)/'
							}
							RED="\\[\\033[01;31m\\]"
							YELLOW="\\[\\033[01;33m\\]"
							GREEN="\\[\\033[01;32m\\]"
							BLUE="\\[\\033[01;34m\\]"
							NO_COLOR="\\[\\033[00m\\]"
							# without host
							PS1="$GREEN\\u$NO_COLOR:$BLUE\\w$YELLOW\\$(parse_git_branch)$NO_COLOR\\$ "
							# with host
							# PS1="$GREEN\\u@\\h$NO_COLOR:$BLUE\\w$YELLOW\\$(parse_git_branch)$NO_COLOR\\$ "`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Enable your changes",
				content: <div>
					<CodeBlock
						text={`sudo source ~/.bashrc`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo source ~/.bashrc");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
		],
		source: "https://gist.github.com/danielalvarenga/2df8cabbd6f3041c2378"
	},
	{
		headerText: "How to Install LAMP Stack with PhpMyAdmin in Ubuntu",
		steps: [
			{
				title: "Install Apache",
				content: <div>
					<CodeBlock
						text={`sudo apt install apache2`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install apache2");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>,
				remarks: <div className='border rounded border-gray-500 bg-gray-300 p-4 shadow mt-2'>
					<p className="mb-1"><b>Note: </b>We can confirm that the <Mention>Apache2</Mention> service is up and enabled on boot using the following</p>
					<CodeBlock
						text={`sudo systemctl status apache2
sudo systemctl is-enabled apache2`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo systemctl status apache2
sudo systemctl is-enabled apache2`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Install PHP",
				content: <ul className="list-disc pl-8">
					<li className="mb-2">
						<p className="mb-1">Add Ondřej Surý PPA repository:</p>
						<CodeBlock
							text={`sudo apt install lsb-release ca-certificates apt-transport-https software-properties-common -y
sudo add-apt-repository ppa:ondrej/php`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo apt install lsb-release ca-certificates apt-transport-https software-properties-common -y
sudo add-apt-repository ppa:ondrej/php`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-2">Install PHP (in this case 8.1 version):</p>
						<CodeBlock
							text={`sudo apt install php8.1`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo apt install php8.1`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li>
						<p className="mb-2">Install basic extensions (<em>Optional</em>):</p>
						<CodeBlock
							text={`sudo apt install php8.1-mysql php8.1-curl php8.1-cli php8.1-mbstring php8.1-xml php8.1-zip`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo apt install php8.1-mysql php8.1-curl php8.1-cli php8.1-mbstring php8.1-xml php8.1-zip`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
				</ul>
			},
			{
				title: <>Install MariaDB Database</>,
				content: <div>
					<CodeBlock
						text={`sudo apt install mariadb-server mariadb-client`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install mariadb-server mariadb-client");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>,
				remarks: <div className='border rounded border-gray-500 bg-gray-300 p-4 shadow mt-2'>
					<p className="mb-1"><b>Note: </b>We can confirm that the MariaDB database service is up and enabled on boot using the following</p>
					<CodeBlock
						text={`sudo systemctl status mariadb
sudo systemctl is-enabled mariadb`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo systemctl status mariadb
sudo systemctl is-enabled mariadb`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
					<p className="my-2">On production servers, we need to enable some basic security measures for the MariaDB database installation, by running the <Mention>mysql_secure_installation</Mention> script which ships with the MariaDB package.</p>
					<CodeBlock
						text={`sudo mysql_secure_installation`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo mysql_secure_installation`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: <>Install PhpMyAdmin (<em>Optional</em>)</>,
				content: <div>
					<CodeBlock
						text={`sudo apt install phpmyadmin`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install phpmyadmin");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>,
				remarks: <div className='border rounded border-gray-500 bg-gray-300 p-4 shadow mt-2'>
					<p className="mb-1"><b>Note: </b>We need to configure <Mention>Apache2</Mention> to serve the phpMyAdmin site. Run the following command to symlink the file <Mention>/etc/phpmyadmin/apache.conf</Mention> to <Mention>/etc/apache2/conf-available/phpmyadmin.conf</Mention>. Then enable the <Mention>phpmyadmin.conf</Mention> configuration files for Apache2 and restart the Apache2 service to apply the recent changes</p>
					<CodeBlock
						text={`sudo ln -s /etc/phpmyadmin/apache.conf /etc/apache2/conf-available/phpmyadmin.conf
sudo a2enconf phpmyadmin.conf
sudo systemctl reload apache2.service`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo ln -s /etc/phpmyadmin/apache.conf /etc/apache2/conf-available/phpmyadmin.conf
sudo a2enconf phpmyadmin.conf
sudo systemctl reload apache2.service`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
		],
		source: "https://www.tecmint.com/install-lamp-with-phpmyadmin-in-ubuntu-20-04/"
	}, {
		headerText: "How to Uninstall LAMP Stack in Ubuntu",
		steps: [
			{
				title: "Remove Apache",
				content: <div>
					<CodeBlock
						text={`sudo service apache2 stop
sudo apt-get purge apache2 apache2-utils apache2.2-bin
sudo apt remove apache2.*
sudo apt-get autoremove
whereis apache2
sudo rm -rf /etc/apache2`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install apache2");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Remove PHP version.",
				content: <div>
					<CodeBlock
						text={`sudo apt-get purge \`dpkg -l | grep php8.1| awk '{print $2}' |tr "\\n" " "\`
sudo apt-get purge php8.*
sudo apt-get autoremove --purge
whereis php
sudo rm -rf /etc/php`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo apt-get purge \`dpkg -l | grep php7.4| awk '{print $2}' |tr "\\n" " "\`
sudo apt-get purge php7.*
sudo apt-get autoremove --purge
whereis php
sudo rm -rf /etc/php`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>,
				remarks: <div className='border rounded border-gray-500 bg-gray-300 p-4 shadow mt-2'>
					<p><b>Note: </b>Type your php version before run the above command. I am using <Mention>php8.1</Mention></p>
				</div>
			},
			{
				title: "Remove MySQL or MariaDB",
				content: <div>
					<CodeBlock
						text={`sudo service mysql stop
sudo apt-get remove --purge *mysql\*
sudo apt-get remove --purge mysql-server mysql-client mysql-common -y
sudo rm -rf /etc/mysql
sudo apt-get autoremove
sudo apt-get autoclean`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo service mysql stop
sudo apt-get remove --purge *mysql\*
sudo apt-get remove --purge mysql-server mysql-client mysql-common -y
sudo rm -rf /etc/mysql
sudo apt-get autoremove
sudo apt-get autoclean`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Reboot system",
				content: <div>
					<CodeBlock
						text={`sudo reboot`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo reboot`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			}
		],
		source: "https://gist.github.com/UbuntuEvangelist/9d115b4ca95c330ef6d2dd42f6c133c9"
	}, {
		headerText: "How to Manually Upgrade phpMyAdmin",
		steps: [
			{
				title: "Back up phpMyAdmin",
				content: <ul className="list-disc pl-8">
					<li className="mb-2">
						<p className="mb-1">You should back up your current phpMyAdmin folder by renaming it:</p>
						<CodeBlock
							text={`sudo mv /usr/share/phpmyadmin/ /usr/share/phpmyadmin.bak`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo mv /usr/share/phpmyadmin/ /usr/share/phpmyadmin.bak`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-1">Create a new phpMyAdmin folder:</p>
						<CodeBlock
							text={`sudo mkdir /usr/share/phpmyadmin/`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo mkdir /usr/share/phpmyadmin/`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li>
						<p className="mb-1">Create a new phpMyAdmin folder:</p>
						<CodeBlock
							text={`cd /usr/share/phpmyadmin/`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`cd /usr/share/phpmyadmin/`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
				</ul>
			},
			{
				title: "Download and Extract phpMyAdmin",
				content: <ul className="list-disc pl-8">
					<li className="mb-2">
						<p className="mb-1">Visit the <a href="https://www.phpmyadmin.net/downloads/" target="_blank" rel="noopener noreferrer">phpMyAdmin download page</a> and look for the .tar.gz URL and download it using <Mention>wget</Mention>:</p>
						<CodeBlock
							text={`sudo wget https://files.phpmyadmin.net/phpMyAdmin/5.1.1/phpMyAdmin-5.1.1-all-languages.tar.gz`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo wget https://files.phpmyadmin.net/phpMyAdmin/5.1.1/phpMyAdmin-5.1.1-all-languages.tar.gz`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-1">Extract:</p>
						<CodeBlock
							text={`sudo tar xzf phpMyAdmin-5.1.1-all-languages.tar.gz`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo tar xzf phpMyAdmin-5.1.1-all-languages.tar.gz`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li>
						<p className="mb-1">Move the contents of this folder to <Mention>/usr/share/phpmyadmin</Mention>:</p>
						<CodeBlock
							text={`sudo mv phpMyAdmin-5.1.1-all-languages/* /usr/share/phpmyadmin`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo mv phpMyAdmin-5.1.1-all-languages/* /usr/share/phpmyadmin`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
				</ul>
			},
			{
				title: <span>Edit <Mention>vendor_config.php</Mention></span>,
				content: <ul className="list-disc pl-8">
					<li className="mb-2">
						<p className="mb-1">Open <Mention>vendor_config.php</Mention>:</p>
						<CodeBlock
							text={`sudo nano /usr/share/phpmyadmin/libraries/vendor_config.php`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo nano /usr/share/phpmyadmin/libraries/vendor_config.php`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-1">Press <Mention>CTRL + W</Mention> and search for <Mention>TEMP_DIR</Mention> If you see something like below in <Mention>/usr/share/phpmyadmin/libraries/vendor_config.php</Mention>, delete it:</p>
						<CodeBlock
							text={`define('TEMP_DIR', ROOT_PATH . 'tmp' . DIRECTORY_SEPARATOR);`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`define('TEMP_DIR', ROOT_PATH . 'tmp' . DIRECTORY_SEPARATOR);`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-1">Add new line in <Mention>/usr/share/phpmyadmin/libraries/vendor_config.php</Mention>, save file and exit:</p>
						<CodeBlock
							text={`define('TEMP_DIR', '/var/lib/phpmyadmin/tmp/');`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`define('TEMP_DIR', '/var/lib/phpmyadmin/tmp/');`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li className="mb-2">
						<p className="mb-1">Open <Mention>vendor_config.php</Mention>:</p>
						<CodeBlock
							text={`sudo nano /usr/share/phpmyadmin/libraries/vendor_config.php`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo nano /usr/share/phpmyadmin/libraries/vendor_config.php`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li>
						<p className="mb-1">Press <Mention>CTRL + W</Mention> and search for <Mention>CONFIG_DIR</Mention>. Then change line to:</p>
						<CodeBlock
							text={`define('CONFIG_DIR', '/etc/phpmyadmin/');`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`define('CONFIG_DIR', '/etc/phpmyadmin/');`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
				</ul>
			},
			{
				title: "Cleanup",
				content: <ul className="list-disc pl-8">
					<li className="mb-2">
						<p className="mb-1">delete the <Mention>tar.gz</Mention> file and the empty folder:</p>
						<CodeBlock
							text={`sudo rm /usr/share/phpmyadmin/phpMyAdmin-5.1.1-all-languages.tar.gz`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["my-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo rm /usr/share/phpmyadmin/phpMyAdmin-5.1.1-all-languages.tar.gz`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
						<CodeBlock
							text={`sudo rm -rf /usr/share/phpmyadmin/phpMyAdmin-5.1.1-all-languages`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo rm -rf /usr/share/phpmyadmin/phpMyAdmin-5.1.1-all-languages`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
					<li>
						<p className="mb-1">And if you’re certain your new phpMyAdmin install is working correctly you can delete the backup folder:</p>
						<CodeBlock
							text={`sudo rm -rf /usr/share/phpmyadmin.bak`}
							language="bash"
							showLineNumbers={false}
							theme={dracula}
						/>
						<Button
							classes={["mt-2"]}
							iconClass="far fa-copy"
							onClick={() => {
								copyToClipboard(`sudo rm -rf /usr/share/phpmyadmin.bak`);
								Store.addNotification({
									title: "SUCCESS!",
									message: "Snippet copied successfully",
									type: "success",
									insert: "top",
									container: "top-right",
									animationIn: ["animate__animated", "animate__fadeIn"],
									animationOut: ["animate__animated", "animate__fadeOut"],
									dismiss: {
										duration: 5000,
										onScreen: true,
										showIcon: true
									}
								});
							}}
							text="Copy Snippet"
							type={ButtonType.SUCCESS}
						/>
					</li>
				</ul>
			},
		],
		source: "https://devanswers.co/manually-upgrade-phpmyadmin/"
	}, {
		headerText: "How to install Geoserver in Ubuntu",
		steps: [
			{
				title: "Install JRE",
				content: <div>
					<CodeBlock
						text={`sudo apt-get install openjdk-8-jre`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt-get install openjdk-8-jre");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Install Postrgresql",
				content: <div>
					<CodeBlock
						text={`sudo apt install postgresql postgresql-contrib`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install postgresql postgresql-contrib");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Install postgis",
				content: <div>
					<CodeBlock
						text={`sudo apt install postgis`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("sudo apt install postgis");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Create Database and User",
				content: <div>
					<CodeBlock
						text={`sudo -u postgres createuser -P geoserver
sudo -u postgres createdb -O geoserver geoserver
sudo -u postgres psql -c "CREATE EXTENSION postgis; CREATE EXTENSION postgis_topology;" geoserver`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo -u postgres createuser -P geoserver
sudo -u postgres createdb -O geoserver geoserver
sudo -u postgres psql -c "CREATE EXTENSION postgis; CREATE EXTENSION postgis_topology;" geoserver`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</div>
			},
			{
				title: "Install Geoserver",
				content: <div>
					<CodeBlock
						text={`sudo apt install unzip
mkdir -p /var/www/geoserver
cd /var/www/geoserver
wget https://netcologne.dl.sourceforge.net/project/geoserver/GeoServer/2.14.1/geoserver-2.14.1-bin.zip
unzip geoserver-2.14.1-bin.zip
mv geoserver-2.14.1/* .
echo "export GEOSERVER_HOME=/var/www/geoserver" >> ~/.profile
. ~/.profiler`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`sudo apt install unzip
mkdir -p /var/www/geoserver
cd /var/www/geoserver
wget https://netcologne.dl.sourceforge.net/project/geoserver/GeoServer/2.14.1/geoserver-2.14.1-bin.zip
unzip geoserver-2.14.1-bin.zip
mv geoserver-2.14.1/* .
echo "export GEOSERVER_HOME=/var/www/geoserver" >> ~/.profile
. ~/.profiler`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
					<p className="my-2">Run Geoserver:</p>
					<CodeBlock
						text={`./bin/startup.sh`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard(`./bin/startup.sh`);
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
					<p className="my-2">Open <a href="http://localhost:8080/geoserver/web/" target="_blank">http://localhost:8080/geoserver/web/</a></p>
				</div>
			}
		],
		source: ["https://gist.github.com/iacovlev-pavel/9006ba2f33cafc8d2ab71528f25f9f45", "https://medium.com/random-gis-talks/installing-geoserver-binary%EF%B8%8F-on-ubuntu-18-04-using-terminal-ff9429ab47fa"]
	}
];

export default data;
