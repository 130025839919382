import Header from "../../Components/UI/Header/Header";
import data from "./__data__/data";

const Linux = () => {

	return (
		<div className="w-full gap-6 flex flex-col">
			{
				data.map((noteData, idx) =>
					<div
						className={`${idx !== data.length - 1 && "border-b border-gray-300 pb-6"}`}
						key={idx}
					>
						<Header text={noteData.headerText} />
						{
							noteData.source && <div className="mt-2">
								Source: {
									Array.isArray(noteData.source)
										? noteData.source.map((source, idx) => {
											return <span key={idx}><a className="" href={source} target="_blank" rel="noopener noreferrer">Open</a>{idx !== noteData.source.length-1 && ", "}</span>
										})
										: <a className="" href={noteData.source} target="_blank" rel="noopener noreferrer">Open</a>
								}
							</div>
						}
						<div className="my-3">
							{
								noteData.steps.map((step, stepIdx) => {
									return <div className="mt-4" key={"step" + stepIdx}>
										<p className={`text-lg font-semibold ${stepIdx !== 0 && "mt-7"} mb-3 border-y border-gray-400 p-2`}><b>Step {stepIdx + 1}. </b>{step.title}</p>
										<div className="px-2">
											{step.content}
											{step.remarks ?? null}
										</div>
									</div>
								})
							}
						</div>
					</div>)
			}
		</div>
	);
};

export default Linux;
