import { CodeBlock, dracula } from "react-code-blocks";
import { Store } from 'react-notifications-component';

import Button from "../../../Components/UI/Button/Button";
import { ButtonType } from "../../../Components/UI/Button/constants";
import Mention from "../../../Components/UI/Mention/Mention";
import { copyToClipboard } from "../../../Utils/Utils";

const data = [
	{
		headerText: "Pure Component vs Component",
		content: <div>
			<p className="mb-3">The major difference between React.PureComponent and React.Component is PureComponent does a shallow comparison on state change. It means that when comparing scalar values it compares their values, but when comparing objects it compares only references. It helps to improve the performance of the app.</p>
			<p className="mb-3">You should go for React.PureComponent when you can satisfy any of the below conditions.</p>
			<ol className="list-decimal pl-8">
				<li className="mb-2">State/Props should be an immutable object</li>
				<li className="mb-2">State/Props should not have a hierarchy</li>
				<li className="mb-2">You should call forceUpdate when data changes</li>
				<li className="mb-2">If you are using React.PureComponent you should make sure all child components are also pure</li>
			</ol>
		</div>,
		source: "https://stackoverflow.com/a/43936258"
	}, {
		headerText: "How to update access token in git",
		content: <div>
			<ol className="list-decimal pl-8">
				<li className="mb-2">Create the new access token in github. <span className="font-bold text-red-600">Copy it because you won't see it again!!!</span></li>
				<li className="mb-2">Open terminal inside the git project.</li>
				<li className="mb-2">
					<p className="mb-2">Enter:</p>
					<CodeBlock
						text={`git remote remove origin`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("git remote remove origin");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</li>
				<li className="mb-2">
					<p className="mb-2">Enter:</p>
					<CodeBlock
						text={`git remote add origin https://__Personal_Access_Token__@github.com/__UserName__/__repo_name__.git`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("git remote add origin https://__Personal_Access_Token__@github.com/__UserName__/__repo_name__.git");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
					<div className='border rounded border-gray-500 bg-gray-300 p-4 shadow mt-2'>
						<p className="mb-1">
							<b>Note: </b> <Mention>__Personal_Access_Token__</Mention> is the token that you copied on the previous step, <Mention>__UserName__</Mention> is your username in git provider(e.g. Github, Gitlab etc) and <Mention>__repo_name__</Mention> is the name of the repository where you want to add the origin.
						</p>
					</div>
				</li>
				<li>
					<p className="mb-1">Using the followind command, you should see the newly created remotes.</p>
					<CodeBlock
						text={`git remote -v`}
						language="bash"
						showLineNumbers={false}
						theme={dracula}
					/>
					<Button
						classes={["mt-2"]}
						iconClass="far fa-copy"
						onClick={() => {
							copyToClipboard("git remote -v");
							Store.addNotification({
								title: "SUCCESS!",
								message: "Snippet copied successfully",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
									onScreen: true,
									showIcon: true
								}
							});
						}}
						text="Copy Snippet"
						type={ButtonType.SUCCESS}
					/>
				</li>
			</ol>
		</div>,
		source: "https://blog.devgenius.io/how-to-update-git-remote-to-use-github-personal-access-token-instead-of-password-c6dace4c416d"
	}, {
		headerText: ".zshrc / .bashrc git aliases",
		content: <ul className="list-disc pl-8">
			<li className="mb-2"><Mention>alias gs='git status -s'</Mention></li>
			<li className="mb-2"><Mention>alias gf='git fetch -v'</Mention></li>
			<li className="mb-2"><Mention>alias gb='git branch -v'</Mention></li>
			<li className="mb-2"><Mention>alias gpm='git push origin master'</Mention></li>
			<li className="mb-2"><Mention>alias gp='git push'</Mention></li>
			<li className="mb-2"><Mention>alias gmm='git merge origin/master'</Mention></li>
			<li className="mb-2"><Mention>alias gm='git merge'</Mention></li>
			<li className="mb-2"><Mention>alias gl="git log --graph --abbrev-commit --decorate --format=format:'%C(bold blue)%h%C(reset) - %C(bold cyan)%aD%C(reset) %C(bold green)(%ar)%C(reset)%C(bold yellow)%d%C(reset)%n''%C(white)%s%C(reset) %C(dim white)- %an%C(reset)' --all"</Mention></li>
			<li className="mb-2"><Mention>alias ga='git add .'</Mention></li>
			<li className="mb-2"><Mention>alias gdt='git describe --tags'</Mention></li>
		</ul>
	}, {
		headerText: "VSCODE settings.json configuration",
		content: <div>
			<CodeBlock
				text={`{
	"editor.minimap.enabled": false,
	"workbench.colorCustomizations": {
	"tab.activeBackground": "#000",
	"tab.activeBorderTop": "#007acc",
	"tab.inactiveBackground": "#3c3c3c",
	"tab.hoverForeground": "#fff"
}`}
				language="json"
				showLineNumbers={false}
				theme={dracula}
			/>
			<Button
				classes={["mt-2"]}
				iconClass="far fa-copy"
				onClick={() => {
					copyToClipboard(`{
	"editor.minimap.enabled": false,
	"workbench.colorCustomizations": {
	"tab.activeBackground": "#000",
	"tab.activeBorderTop": "#007acc",
	"tab.inactiveBackground": "#3c3c3c",
	"tab.hoverForeground": "#fff"
}`);
					Store.addNotification({
						title: "SUCCESS!",
						message: "Snippet copied successfully",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: true,
							showIcon: true
						}
					});
				}}
				text="Copy Snippet"
				type={ButtonType.SUCCESS}
			/>
		</div>
	},
];

export default data;
