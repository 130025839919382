import Mention from "../../../Components/UI/Mention/Mention";

const data = {
	utils: [
		{
			codeBlock: `npm install --save axios`,
			description: "Useful to manage AJAX calls.",
			source: "https://www.npmjs.com/package/axios"
		},
		{
			codeBlock: `npm install --save env-cmd`,
			description: <span>Useful to manage <Mention>.env</Mention> files.</span>,
			source: "https://www.npmjs.com/package/env-cmd"
		},
		{
			codeBlock: `npm install --save generate-password`,
			description: "Useful to generate passwords.",
			source: "https://www.npmjs.com/package/generate-password"
		},
		{
			codeBlock: `npm install --save jquery`,
			description: <span>Install <Mention>JQuery</Mention> through npm.</span>,
			source: "https://www.npmjs.com/package/jquery"
		},
		{
			codeBlock: `npm install --save moment`,
			description: "Useful to manipulate dates.",
			source: "https://www.npmjs.com/package/moment"
		},
		{
			codeBlock: `npm install --save http-status-codes`,
			description: "Useful to manage http codes and messages.",
			source: "https://www.npmjs.com/package/http-status-codes"
		},
		{
			codeBlock: `npm install --save uuid`,
			description: "Useful to create RFC4122 UUIDs.",
			source: "https://www.npmjs.com/package/uuid"
		},
		{
			codeBlock: `npm install --save greek-utils`,
			description: "Useful to convert greek to greeklish and the opposite.",
			source: "https://www.npmjs.com/package/greek-utils"
		},
		{
			codeBlock: `npm install --save lodash`,
			description: "Useful array, object and more utility library.",
			source: "https://www.npmjs.com/package/lodash"
		},
		{
			codeBlock: `npm install --save prop-types`,
			description: "Runtime type checking for React props and similar objects.",
			source: "https://www.npmjs.com/package/prop-types"
		},
	],
	gui: [
		{
			codeBlock: `npm install --save bootstrap`,
			description: <span>Install <Mention>Bootstrap</Mention> through npm</span>,
			source: "https://www.npmjs.com/package/bootstrap"
		},
		{
			codeBlock: `npm install --save chart.js`,
			description: <span>Install <Mention>ChartJS</Mention> through npm</span>,
			source: "https://www.npmjs.com/package/chart.js"
		},
		{
			codeBlock: `npm install --save react-data-table-component`,
			description: "Useful for table grids.",
			source: "https://www.npmjs.com/package/react-data-table-component"
		},
		{
			codeBlock: `npm install --save reactstrap`,
			description: <span>Stateless React Components for <Mention>Bootstrap 5.</Mention></span>,
			source: "https://www.npmjs.com/package/reactstrap"
		},
		{
			codeBlock: `npm install --save react-select`,
			description: "Useful for dropdowns.",
			source: "https://www.npmjs.com/package/react-select"
		},
		{
			codeBlock: `npm install --save react-notifications`,
			description: "Useful to create notification messages.",
			source: "https://www.npmjs.com/package/react-notifications"
		},
		{
			codeBlock: `npm install --save react-tooltip`,
			description: "Useful to create tooltips.",
			source: "https://www.npmjs.com/package/react-tooltip"
		},
		{
			codeBlock: `npm install --save react-datepicker`,
			description: "A simple and reusable Datepicker component for React.",
			source: "https://www.npmjs.com/package/react-datepicker"
		},
		{
			codeBlock: `npm install --save react-tabs`,
			description: "An accessible and easy tab component.",
			source: "https://www.npmjs.com/package/react-tabs"
		},
	],
};

export default data;
