import PropTypes from 'prop-types';

const Mention = ({ children }) => {
	return (
		<span className='italic text-pink-500 font-normal mx-1'>{children}</span>
	)
}

Mention.propTypes = {
	children: PropTypes.string.isRequired
};

export default Mention;
