import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {

	const { pathname } = useLocation();

	useEffect(() => {
		if (document.getElementById("page-wrapper"))
			document.getElementById("page-wrapper").scrollTo({ top: 0 });
	}, [pathname])


	return null;
}

export default ScrollToTop;
