import { CodeBlock, dracula } from "react-code-blocks";
import { Store } from 'react-notifications-component';

import Mention from "../Components/UI/Mention/Mention";
import Button from "../Components/UI/Button/Button";
import { ButtonType } from "../Components/UI/Button/constants";
import { copyToClipboard } from '../Utils/Utils';

const data = {
	php: [
		{
			headerText: "Create CSRF token and save it to session",
			codeBlock: `function setCsrfToken(){
	if (!isset($_SESSION['csrf_token'])) {
		$_SESSION['csrf_token'] = bin2hex(random_bytes(32));
	}
}
`,
			codeLanguage: "php",
			source: "https://stackoverflow.com/a/31683058"
		},
		{
			headerText: "Verify CSRF token",
			codeBlock: `if (!empty($_POST['csrf_token'])) {
	if (hash_equals($_SESSION['csrf_token'], $_POST['csrf_token'])) {
		// Proceed to process the form data
	} else {
		// Log this as a warning and keep an eye on these attempts
	}
}
`,
			codeLanguage: "php",
			source: "https://stackoverflow.com/a/31683058"
		},
		{
			headerText: "Algorithm to generate custom length random string",
			codeBlock: `function generateRandomString($length = 50){
	$characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#()-_?$%^&*';
	$charactersLength = strlen($characters);
	$randomString = '';
	for ($i = 0; $i < $length; $i++) {
		$randomString .= $characters[rand(0, $charactersLength - 1)];
	}
	if (!preg_match('/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#()-_?$%^&*])(?=.{8,})/', $randomString)) {
		generateRandomString();
	}
	return $randomString;
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Algorithm to sanitize incoming parameter from an api call",
			codeBlock: `function SAFE_INPUT(string $param, bool $is_email = false){
	global $input;
	$filter = FILTER_SANITIZE_STRING;
	if (is_int($input[$param])) {
		$filter = FILTER_SANITIZE_NUMBER_INT;
	} elseif (is_float($input[$param])) {
		$filter = FILTER_SANITIZE_NUMBER_FLOAT;
	} elseif (is_bool($input[$param])) {
		$filter = FILTER_VALIDATE_BOOLEAN;
	} elseif (is_array($input[$param])) {
		$filter = FILTER_DEFAULT;
	} elseif (is_null($input[$param])) {
		return null;
	} elseif ($is_email) {
		$filter = FILTER_SANITIZE_EMAIL;
	}

	if ($filter === FILTER_DEFAULT)
		return filter_var_array($input[$param], $filter);
	else
		return filter_var($input[$param], $filter);
}

$tmp = file_get_contents('php://input');
$input = json_decode($tmp, true);
`,
			codeLanguage: "php",
			remarks: <div>
				<b>Note: </b>the input variable created from the code:
				<CodeBlock
					text={`$tmp = file_get_contents('php://input');
$input = json_decode($tmp, true);`}
					language="php"
					showLineNumbers={false}
					theme={dracula}
				/>
			</div>
		},
		{
			headerText: "Class that creates a proper Response to the caller",
			codeBlock: `class AjaxController{
	public $code = 200;
	public $error = [];
	public $data = '';

	function __construct($code = 200, $data = '', $errorMessage = []){
		$this->code = $code;
		$this->data = $data;
		$this->error = $errorMessage;
	}

	static function spitResponse(AjaxController $response){
		header("Content-Type: application/json");
		header("Expires: on, 01 Jan 1970 00:00:00 GMT");
		header("Last-Modified: " . gmdate("D, d M Y H:i:s") . " GMT");
		header("Cache-Control: no-store, no-cache, must-revalidate");
		header("Cache-Control: post-check=0, pre-check=0", false);
		header("Pragma: no-cache");
		http_response_code($response->code);
		ob_clean();
		echo json_encode($response, JSON_PRETTY_PRINT | JSON_UNESCAPED_UNICODE);
		exit;
	}
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Convert greek word to uppercase without accent algorithm",
			codeBlock: `function uc_without_accents($string, $enc = "utf-8"){
	return strtr(
		 mb_strtoupper($string, $enc),
		 array(
			  'Ά' => 'Α', 'Έ' => 'Ε', 'Ί' => 'Ι', 'Ή' => 'Η', 'Ύ' => 'Υ',
			  'Ό' => 'Ο', 'Ώ' => 'Ω', 'A' => 'A', 'A' => 'A', 'A' => 'A', 'A' => 'A',
			  'Y' => 'Y', 'ΐ' => 'Ϊ'
		 )
	);
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Destroy PHP Session",
			codeBlock: `function destroySession(){
	session_start();
	$_SESSION = array();
	session_unset();
	session_destroy();
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Helper function to print messages inside a pre tag using var_dump or print_r",
			codeBlock: `function helperPrint($data, $mode){
	echo '<pre>';
	if ($mode === "var_dump") {
		 var_dump($data);
	} else {
		 print_r($data);
	}
	echo '</pre>';
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Helper function to minify html",
			codeBlock: `function minifyHtml($html) {
	$search = array(
		 // Remove whitespaces after tags
		 '/\>[^\S ]+/s',
		 // Remove whitespaces before tags
		 '/[^\S ]+\</s',
		 // Remove multiple whitespace sequences
		 '/(\s)+/s',
		 // Removes comments
		 '/<!--(.|\s)*?-->/'
	);
	$replace = array('>', '<', '\\1');
	$code = preg_replace($search, $replace, $html);
	return $code;
}
`,
			codeLanguage: "php"
		},
		{
			headerText: "Helper function to translate greek to greeklish",
			codeBlock: `function greek_to_greeglish($string){
	$g2l["ου"] = "ou";
	$g2l["Ου"] = "Ou";
	$g2l["ού"] = "ou";
	$g2l["Ού"] = "Ou";
	$g2l["α"] = "a";
	$g2l["ά"] = "a";
	$g2l["Α"] = "A";
	$g2l["Ά"] = "A";
	$g2l["β"] = "v";
	$g2l["Β"] = "V";
	$g2l["γ"] = "g";
	$g2l["Γ"] = "G";
	$g2l["δ"] = "d";
	$g2l["Δ"] = "D";
	$g2l["ε"] = "e";
	$g2l["έ"] = "e";
	$g2l["Ε"] = "E";
	$g2l["Έ"] = "E";
	$g2l["ζ"] = "z";
	$g2l["Ζ"] = "Z";
	$g2l["η"] = "i";
	$g2l["ή"] = "i";
	$g2l["Η"] = "I";
	$g2l["Ή"] = "I";
	$g2l["θ"] = "th";
	$g2l["Θ"] = "Th";
	$g2l["ι"] = "i";
	$g2l["ί"] = "i";
	$g2l["ϊ"] = "i";
	$g2l["ΐ"] = "i";
	$g2l["Ι"] = "I";
	$g2l["Ί"] = "I";
	$g2l["Ϊ"] = "I";
	$g2l["κ"] = "k";
	$g2l["Κ"] = "K";
	$g2l["λ"] = "l";
	$g2l["Λ"] = "L";
	$g2l["μ"] = "m";
	$g2l["Μ"] = "M";
	$g2l["ν"] = "n";
	$g2l["Ν"] = "N";
	$g2l["ξ"] = "ks";
	$g2l["Ξ"] = "Ks";
	$g2l["ο"] = "o";
	$g2l["ό"] = "o";
	$g2l["Ο"] = "O";
	$g2l["Ό"] = "O";
	$g2l["π"] = "p";
	$g2l["Π"] = "P";
	$g2l["ρ"] = "r";
	$g2l["Ρ"] = "R";
	$g2l["σ"] = "s";
	$g2l["ς"] = "s";
	$g2l["Σ"] = "S";
	$g2l["τ"] = "t";
	$g2l["Τ"] = "T";
	$g2l["υ"] = "u";
	$g2l["ύ"] = "u";
	$g2l["ϋ"] = "u";
	$g2l["ΰ"] = "u";
	$g2l["Υ"] = "U";
	$g2l["Ύ"] = "U";
	$g2l["Ϋ"] = "I";
	$g2l["φ"] = "f";
	$g2l["Φ"] = "F";
	$g2l["χ"] = "h";
	$g2l["Χ"] = "H";
	$g2l["ψ"] = "ps";
	$g2l["Ψ"] = "Ps";
	$g2l["ω"] = "o";
	$g2l["ώ"] = "o";
	$g2l["Ω"] = "O";
	$g2l["Ώ"] = "O";
	$g2l[" "] = "_";
	$g2l["-"] = "_";
	$g2l["."] = "_";
	$g2l["/"] = "_";
	$output = strtr($string, $g2l);
	return $output;
}
`,
			codeLanguage: "php",
			source: "https://www.insomnia.gr/forums/topic/534687-php-%CE%BC%CE%B5%CF%84%CE%B1%CF%84%CF%81%CE%BF%CF%80%CE%AE-%CE%B5%CE%BB%CE%BB%CE%B7%CE%BD%CE%B9%CE%BA%CE%AC-%CF%83%CE%B5-greeglish-function/"
		},
		{
			headerText: "Openssl Encryption and Decryption",
			codeBlock: `//ENCRYPTION
//$key should have been previously generated in a cryptographically safe way, like openssl_random_pseudo_bytes
$plaintext = "message to be encrypted";
$cipher = "aes-128-gcm";
if (in_array($cipher, openssl_get_cipher_methods()))
{
	 $ivlen = openssl_cipher_iv_length($cipher);
	 $iv = openssl_random_pseudo_bytes($ivlen);
	 $ciphertext = openssl_encrypt($plaintext, $cipher, $key, $options=0, $iv, $tag);
	 //store $cipher, $iv, and $tag for decryption later
	 $original_plaintext = openssl_decrypt($ciphertext, $cipher, $key, $options=0, $iv, $tag);
	 echo $original_plaintext."\n";
}
//DECRYPTION
$c = base64_decode($ciphertext);
$ivlen = openssl_cipher_iv_length($cipher="AES-128-CBC");
$iv = substr($c, 0, $ivlen);
$hmac = substr($c, $ivlen, $sha2len=32);
$ciphertext_raw = substr($c, $ivlen+$sha2len);
$original_plaintext = openssl_decrypt($ciphertext_raw, $cipher, $key, $options=OPENSSL_RAW_DATA, $iv);
$calcmac = hash_hmac('sha256', $ciphertext_raw, $key, $as_binary=true);
if (hash_equals($hmac, $calcmac))// timing attack safe comparison
{
	 echo $original_plaintext."\n";
}
`,
			codeLanguage: "php",
			remarks: <span><b>Note:</b> If<Mention>iv</Mention> must be static, then you have to generate a random 16char string and save it to your database</span>,
			source: "https://www.php.net/manual/en/function.openssl-encrypt.php"
		}
	],
	wordpress: [
		{
			headerText: "How to get posts data, custom fields included",
			codeBlock: `function acf_to_rest_api($response, $post, $request) {
	if (!function_exists('get_fields')) return $response;

	if (isset($post)) {
		$acf = get_fields($post->id);
		$response->data['acf'] = $acf;
	}
	return $response;
}
add_filter('rest_prepare_post', 'acf_to_rest_api', 10, 3);
`,
			codeLanguage: "php",
			remarks: <span><b>Note:</b> Paste the above inside<Mention>functions.php</Mention>.</span>,
		},
		{
			headerText: "How to get custom post type data, custom fields included",
			codeBlock: `add_action('rest_api_init', function () {
	register_rest_route('wp/v2', '/some_api/', array(
		 'methods' => 'GET',
		 'callback' => 'some_api'
	));
});
//callback function
function some_api(){
	$args = array(
		 'post_type' => 'some_api',
		 'post_status' => 'publish',
		 'nopaging' => true
	);
	$query = new WP_Query($args);
	$posts = $query->get_posts();
	$output = array();
	foreach ($posts as $post) {
		// Pluck the id and title attributes
		if (function_exists('CFS')) {
			$custom_fields = CFS()->get(false, $post->ID, array( 'format' => 'raw' ));
			$output[] = array('post' => $post, 'custom_fields' => $custom_fields);
		} elseif (function_exists('get_fields')) {
			$acf = get_fields($post->ID);
			$output[] = array('post' => $post, 'custom_fields' => $acf);
		} else {
			$output[] = array('post' => $post);
		}
	}
	wp_send_json($output); // getting data in json format.
}
`,
			codeLanguage: "php",
			remarks: <span><b>Note:</b> The above example takes<Mention>some_api</Mention> as custom post type name. Also, searches for <b><a href="https://wordpress.org/plugins/custom-field-suite/" target="_blank">CFS</a></b> and <b><a href="https://www.advancedcustomfields.com/" target="_blank">ACF</a></b> fuctions. Paste the above inside<Mention>functions.php</Mention>.</span>
		}
	],
	javascript: [
		{
			headerText: "Function that copies to clickboard a string",
			codeBlock: `export const copyToClipboard = str => {
	navigator.clipboard.writeText(str);
};
`,
			codeLanguage: "javascript"
		},
		{
			headerText: "Function that returns the number of days that pasts until today, given a string typed date",
			codeBlock: `export const getDaysPastUntilToday = (startDate) => {
	const timeDiff = (new Date() - new Date(startDate));
	return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
}
`,
			codeLanguage: "javascript",
			remarks: <span><b>Note: </b><span className="mention">startDate</span> parameter must be string.</span>
		},
		{
			headerText: "Function that sorts an Array of Objects by date field",
			codeBlock: `export const sortArrayOfObjectsByDate = (array, fieldNameToSort, order) => {
	if (!b[fieldNameToSort] || ! a[fieldNameToSort]){
		console.error("Wrong field name to sort.");
		return null;
	}
	if (order === "desc")
		return array.sort((a, b) => new Date(b[fieldNameToSort]).getTime() - new Date(a[fieldNameToSort]).getTime());
	else if (order === 'asc')
		return array.sort((a, b) => new Date(a[fieldNameToSort]).getTime() - new Date(b[fieldNameToSort]).getTime());
	else{
		console.error("Available values for order parameter is asc or desc");
		return null;
	}
}
`,
			codeLanguage: "javascript"
		},
		{
			headerText: "Function that sorts an Array of Objects by a number field",
			codeBlock: `export const sortArrayOfObjectsByNumbers = (array, fieldNameToSort, order) => {
	if (!b[fieldNameToSort] || ! a[fieldNameToSort]){
		console.error("Wrong field name to sort.");
		return null;
	}
	return array.sort((a, b) => {
		 let comparison = 0;
		 if (order === "desc") {
			  if (a[fieldNameToSort] < b[fieldNameToSort]) {
					comparison = 1;
			  } else if (a[fieldNameToSort] > b[fieldNameToSort]) {
					comparison = -1;
			  }
		 } else if (order === "asc){
			  if (a[fieldNameToSort] > b[fieldNameToSort]) {
					comparison = 1;
			  } else if (a[fieldNameToSort] < b[fieldNameToSort]) {
					comparison = -1;
			  }
		 } else {
			console.error("Available values for order parameter is asc or desc");
			return null;
		}
		 return comparison;
	});
};
`,
			codeLanguage: "javascript"
		},
		{
			headerText: "Function that converts an Object to FormData Object",
			codeBlock: `export const toFormData = (input) => {
	const form = new FormData();
	Object.keys(input).map(
		theKey => {
			if (Array.isArray(input[theKey])) {
				for (let i = 0; i < input[theKey].length; i++) {
					form.append(theKey + '[]', input[theKey][i]);
				}
			} else {
				form.append(theKey, input[theKey])
			}
			return form;
		 }
	);
	return form;
};
`,
			codeLanguage: "javascript"
		},
		{
			headerText: "Function that deeply copy an array or object",
			codeBlock: `export function clone(o) {
	return JSON.parse(JSON.stringify(o));
}
`,
			codeLanguage: "javascript",
			remarks: <span><b>Notes: </b>This will fail for Date objects because Date will become a string</span>
		},
		{
			headerText: "Functions that convert empty strings and/or undefined to null",
			codeBlock: `function emptyStringNullifier(key, value) {
	if (value==='')
		return null;
	else
		return value;
}

function undefinedNullifier(key, value) {
	if (value===undefined)
		return null;
	else
		return value;
}

export function nullifyEmptyStrings(o) {
	return JSON.parse(JSON.stringify(o, emptyStringNullifier));
}

export function substituteUndefinedWithNull(o) {
	return JSON.parse(JSON.stringify(o, undefinedNullifier));
}

export function substituteUndefinedWithNullAndNullifyEmptyStrings(o) {
	// the order is important!
	return nullifyEmptyStrings(substituteUndefinedWithNull(o));
}
`,
			codeLanguage: "javascript",
			remarks: <span><b>Notes: </b>This will fail for Date objects because Date will become a string</span>
		},
		{
			headerText: "Create axios interceptor example code",
			codeBlock: `const axiosAuth = axios.create({ baseURL: BASE_URL });

axiosAuth.interceptors.request.use(config => {
	 Object.assign(config.headers, {
		  Authtoken: token
	 });
	 return config;
}, error => {
	 return Promise.reject("Error while assigning Authtoken header to axios request");
});

export {axiosAuth};
`,
			codeLanguage: "javascript",
			remarks: <span><b>Notes: </b>In above code, firstly we added the baseUrl (to minimize the url repetition) and then we create an interceptor to include in every api call the header<Mention>Authtoken</Mention></span>
		},
		{
			headerText: "How to export a ChartJS chart to an image",
			codeBlock: `const url_base64 = document.getElementById('elementId').toDataURL('image/png');
const link = document.createElement('a');
link.setAttribute('href', url_base64);
link.setAttribute('download', "filename.png");
link.click();
`,
			codeLanguage: "javascript",
			remarks: <span><b>Notes: </b>In above code the <Mention>elementId</Mention> refers to the div that contains the chart.</span>,
			source: "https://stackoverflow.com/a/39076365"
		},
	]
}

export default data;
